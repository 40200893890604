import localIqImg from '../images/logo-localiq.svg';
import wordstreamLogo from '../images/logo-wordstream.svg';

export const DEFAULT_FREEMIUM_TEMPLATE = {
  key: 'freemium',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Get started with your FREE account',
    description: 'Unlock the tools, resources, and information needed to manage and market your business more effectively.',
    ctaText: 'Get Started',
    loadingText: 'Getting started...',
    bulletList: [],
    shortenedLegalText: false,
    termsAndServicesText: null,
  },
  createBusiness: {
    title: 'Almost done!',
    description: 'Please tell us a bit more about your business to customize your LocaliQ experience.',
    ctaText: 'Create Account',
    bulletList: [],
    loading: {
      headerText: 'We\'re creating your account',
      footerText: 'This may take up to 10 seconds. We appreciate your patience.',
      progressRef: {
        33: 'Welcome to LocaliQ!',
        66: 'We\'ve worked with over 300,000 customers delivering more than 285 Million leads.',
        99: 'Our marketing platform helps you find, convert and keep customers.',
      },
    },
  },
  login: {
    ctaText: 'Sign In',
    loadingText: 'Signing In',
    footerLinkText: 'Sign up',
    footerText: 'Don\'t have an account? ',
    bulletList: [],
  },
};

export const DEFAULT_TEMPLATE = {
  key: 'default',
  logo: localIqImg,
  supportGoogle: false,
  createAccount: DEFAULT_FREEMIUM_TEMPLATE.createAccount,
  createBusiness: DEFAULT_FREEMIUM_TEMPLATE.createBusiness,
  login: {
    ctaText: 'Log In',
    loadingText: 'Logging In',
    footerLinkText: 'Log in here',
    footerText: 'Are you a Lead Capture Toolkit user?',
    bulletList: [],
  },
};

const PRINT_TEMPLATE = {
  key: 'print',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    ctaText: 'Activate Account',
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const GRADER_TEMPLATE = {
  key: 'grader',
  logo: wordstreamLogo,
  supportGoogle: true,
  createAccount: {
    title: 'Get your grade and FREE account',
    description: 'Unlock the tools, resources, and information needed to manage and market your business more effectively.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const CALL_TRACKER_TEMPLATE = {
  key: 'calltracker',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Start recording & tracking your calls today!',
    description: 'Gain insights from your customers & grow your business with better engagement.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const CHATBOT_TEMPLATE = {
  key: 'chat',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Add a chat feature to your website today!',
    description: 'Engage with potential customers who visit your site and help convert more users into new leads.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const LISTINGS_TEMPLATE = {
  key: 'listings',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Get started managing your business listings',
    description: 'Ensure your business information is accurate so customers can find you across online directories.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const KEYWORD_TOOL_TEMPLATE = {
  key: 'keywordtool',
  logo: wordstreamLogo,
  supportGoogle: true,
  createAccount: {
    title: 'Get full access to your keywords with a FREE account',
    description: 'Plus more free tools and resources, including the Free Website Grader, Inbound Call Recorder and Chatbot!',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const SCHEDULER_TEMPLATE = {
  key: 'scheduler',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Start accepting appointments today with the Scheduler.',
    description: 'Unlock the tools need to accept, manage and organize client appointment quickly and easily, 24/7.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

const GROWTH_SUITE_TEMPLATE = {
  key: 'growthsuite',
  logo: localIqImg,
  supportGoogle: true,
  createAccount: {
    title: 'Get started with our lead capture tools.',
    description: 'Turn website visitors into new business, build your contact list and keep in touch.',
    ctaText: DEFAULT_TEMPLATE.createAccount.ctaText,
    loadingText: DEFAULT_TEMPLATE.createAccount.loadingText,
  },
  createBusiness: DEFAULT_TEMPLATE.createBusiness,
  login: DEFAULT_TEMPLATE.login,
};

export const TEMPLATE_MAPPING = {
  default: DEFAULT_TEMPLATE,
  freemium: DEFAULT_FREEMIUM_TEMPLATE,
  print: PRINT_TEMPLATE,
  grader: GRADER_TEMPLATE,
  calltracker: CALL_TRACKER_TEMPLATE,
  chat: CHATBOT_TEMPLATE,
  listings: LISTINGS_TEMPLATE,
  keywordtool: KEYWORD_TOOL_TEMPLATE,
  scheduler: SCHEDULER_TEMPLATE,
  growthsuite: GROWTH_SUITE_TEMPLATE,
};
